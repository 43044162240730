import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../firebase/config';

const LandingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Eğer kullanıcı zaten giriş yapmışsa dashboard'a yönlendir
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        navigate('/dashboard');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate('/dashboard');
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const pricingPlans = [
    {
      name: 'Başlangıç',
      price: '29',
      period: 'aylık',
      features: [
        'Temel Sinyaller',
        'Manuel Trading Desteği',
        'Telegram Grup Erişimi',
        'Email Desteği'
      ],
      buttonText: 'Hemen Başla',
      highlighted: false
    },
    {
      name: 'Professional',
      price: '99',
      period: 'aylık',
      features: [
        'Gelişmiş Sinyaller',
        'Otomatik Trading',
        'Otomatik DCA Stratejileri', 
        'Otomatik Hedge İşlem Koruması'
      ],
      buttonText: 'Hemen Başla',
      highlighted: true
    },
    {
      name: 'Enterprise',
      price: '249',
      period: 'aylık',
      features: [
        'Özel Sinyaller', 
        'Özel Strateji Desteği',
        'Risk Yönetimi',
        'Portfolio Analizi'
      ],
      buttonText: 'İletişime Geç',
      highlighted: false
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      {/* Header */}
      <header className="bg-transparent">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <img src="/images/logo.png" alt="ZQ-Signal" className="h-10 w-auto" />
              <span className="ml-3 text-2xl font-bold text-white">ZQ-Signal</span>
            </div>
            <div className="flex gap-4">
              <button
                onClick={() => navigate('/login')}
                className="px-4 py-2 bg-white bg-opacity-10 rounded text-white hover:bg-opacity-20 transition-all duration-200"
              >
                Giriş Yap
              </button>
              <button
                onClick={() => navigate('/signup')}
                className="px-4 py-2 bg-blue-600 rounded text-white hover:bg-blue-700 transition-colors"
              >
                Üye Ol
              </button>
            </div>
          </div>
        </nav>
      </header>

      {/* Hero Section */}
      <main>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16 text-center">
            <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
                Profesyonel Trading Stratejileri
            </h1>
            <p className="text-xl text-gray-300 mb-12 max-w-3xl mx-auto">
                Volatilite ve Fibonacci seviyelerini kullanarak oluşturulan profesyonel işlem stratejileriyle 
                finansal piyasalarda fark yaratın!
            </p>
            <div className="flex justify-center gap-4">
                <button
                onClick={() => navigate('/signup')}
                className="px-8 py-4 bg-white text-blue-600 rounded-lg text-lg font-semibold hover:bg-gray-100 transition-all transform hover:scale-105 flex items-center justify-center gap-2 shadow-lg"
                >
                <i className="fas fa-rocket"></i>
                Haydi Başlayalım
                </button>
            </div>
            </div>

        {/* Features */}
        <div className="bg-gray-800 bg-opacity-50 py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gray-700 p-6 rounded-lg transform hover:-translate-y-1 transition-all duration-200 border border-gray-600 hover:border-blue-400 shadow-lg hover:shadow-xl bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900">
            <div className="text-blue-400 text-4xl mb-4">
   <i className="fas fa-chart-line"></i>
 </div>
 <h3 className="text-xl font-semibold text-white mb-2">Gap Analizi</h3>
 <p className="text-gray-300">
   Gap seviyelerine dayalı işlem sinyalleri ile fırsatları yakalayın
 </p>
</div>
<div className="bg-gray-700 p-6 rounded-lg transform hover:-translate-y-1 transition-all duration-200 border border-gray-600 hover:border-blue-400 shadow-lg hover:shadow-xl bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900">
<div className="text-blue-400 text-4xl mb-4">
                  <i className="fas fa-robot"></i>
                </div>
                <h3 className="text-xl font-semibold text-white mb-2">Otomatik DCA</h3>
                <p className="text-gray-300">
                  Ortalama maliyet düşürme stratejileri ile riskinizi yönetin
                </p>
              </div>
              <div className="bg-gray-700 p-6 rounded-lg transform hover:-translate-y-1 transition-all duration-200 border border-gray-600 hover:border-blue-400 shadow-lg hover:shadow-xl bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900">
              <div className="text-blue-400 text-4xl mb-4">
                  <i className="fas fa-shield-alt"></i>
                </div>
                <h3 className="text-xl font-semibold text-white mb-2">Hedge İşlemleri</h3>
                <p className="text-gray-300">
                  Piyasa dalgalanmalarına karşı pozisyonlarınızı koruyun
                </p>
              </div>
            </div>
          </div>
        </div>



        {/* Stats Section */}
        <div className="py-20 bg-gray-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-center">
              <div className="p-6">
                <div className="text-4xl font-bold text-blue-500 mb-2">1000+</div>
                <div className="text-gray-400">Aktif Kullanıcı</div>
              </div>
              <div className="p-6">
                <div className="text-4xl font-bold text-blue-500 mb-2">24/7</div>
                <div className="text-gray-400">Destek</div>
              </div>
              <div className="p-6">
                <div className="text-4xl font-bold text-blue-500 mb-2">%85</div>
                <div className="text-gray-400">Başarı Oranı</div>
              </div>
              <div className="p-6">
                <div className="text-4xl font-bold text-blue-500 mb-2">5+</div>
                <div className="text-gray-400">Yıllık Deneyim</div>
              </div>
            </div>
          </div>
        </div>


{/* Supported Exchanges Section */}
<div className="bg-gray-800 bg-opacity-50 py-20">
 <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
   <h2 className="text-3xl font-bold text-white mb-4">
     Desteklenen Borsalar
   </h2>
   <p className="text-gray-300 mb-10">
     Direkt entegrasyon ve API desteği ile borsalarınızı tek arayüzden yönetin
   </p>
  {/* Logos büyütüldü: h-8 -> h-12 */}
<div className="grid grid-cols-5 gap-8 mb-8">
 <div className="flex items-center justify-center">
   <img src="/images/exchanges/binance.png" alt="Binance" className="h-12 hover:scale-110 transition-transform" />
 </div>
 <div className="flex items-center justify-center">
   <img src="/images/exchanges/bybit.png" alt="Bybit" className="h-12 hover:scale-110 transition-transform" />
 </div>
 <div className="flex items-center justify-center opacity-40">
   <img src="/images/exchanges/kucoin.png" alt="KuCoin" className="h-12" />
 </div>
 <div className="flex items-center justify-center opacity-40">
   <img src="/images/exchanges/bitget.png" alt="Bitget" className="h-12" />
 </div>
 <div className="flex items-center justify-center opacity-40">
   <img src="/images/exchanges/okx.png" alt="OKX" className="h-12" />
 </div>
</div>
 </div>
</div>


        {/* Pricing */}
        <div className="py-20 bg-gray-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-white text-center mb-12">
              Üyelik Planları
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {pricingPlans.map((plan, index) => (
                <div
                  key={plan.name}
                  className={`bg-gray-700 rounded-lg overflow-hidden transform hover:-translate-y-1 transition-all duration-200 ${
                    plan.highlighted ? 'ring-2 ring-blue-500' : ''
                  }`}
                >
                  <div className="p-6">
                    <h3 className="text-xl font-semibold text-white mb-2">
                      {plan.name}
                    </h3>
                    <div className="text-3xl font-bold text-white mb-4">
                      ${plan.price}
                      <span className="text-lg text-gray-400">/{plan.period}</span>
                    </div>
                    <ul className="space-y-3 mb-6">
                      {plan.features.map((feature, i) => (
                        <li key={i} className="flex items-center text-gray-300">
                          <i className="fas fa-check text-green-400 mr-2"></i>
                          {feature}
                        </li>
                      ))}
                    </ul>
                    <button
                       onClick={() => {
                        if (plan.name === 'Enterprise') {
                          window.location.href = 'mailto:info@zqsignal.com?subject=Enterprise%20Plan%20Bilgi%20Talebi';
                        } else {
                          navigate('/signup');
                        }
                      }}
                      className={`w-full py-3 rounded-lg font-semibold ${
                        plan.highlighted
                          ? 'bg-blue-600 text-white hover:bg-blue-700'
                          : 'bg-gray-600 text-white hover:bg-gray-500'
                      } transition-colors`}
                    >
                      {plan.buttonText}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>


     {/*  trading grafiği */}
     <div className="bg-gray-800 bg-opacity-30 py-20">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-6">
          <img 
            src="/images/trading-chart.jpg" 
            alt="Trading Chart" 
            className="w-full rounded-lg shadow-2xl"
          />
        </div>
      </div>


        {/* CTA Section */}
            {/* CTA Section */}
            <div className="bg-gray-800 bg-opacity-50 py-20">
                          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                <h2 className="text-3xl font-bold text-white mb-8">
                Hemen Başlayın
                </h2>
                <p className="text-xl text-white opacity-90 mb-8 max-w-2xl mx-auto">
                Profesyonel trading stratejilerimizle tanışın ve finansal hedeflerinize ulaşın.
                </p>
                <div className="flex justify-center">
                <button
                    onClick={() => navigate('/signup')}
                    className="px-8 py-4 bg-white text-blue-600 rounded-lg text-lg font-semibold hover:bg-gray-100 transition-colors flex items-center justify-center gap-2"
                >
                    <i className="fas fa-trophy"></i>
                    Haydi Başlayalım
                </button>
                </div>
            </div>
            </div>
      </main>

      {/* Footer */}
      <footer className="bg-gray-900 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-white font-semibold mb-4">Hakkımızda</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white">Şirket</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Kariyer</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Blog</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-white font-semibold mb-4">Ürünler</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white">Trading Botu</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">Sinyaller</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">API</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-white font-semibold mb-4">Destek</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white">Dokümantasyon</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white">SSS</a></li>
                <li><a href= "mailto:info@zqsignal.com?subject=Enterprise%20Plan%20Bilgi%20Talebi" className="text-gray-400 hover:text-white">İletişim</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-white font-semibold mb-4">Sosyal Medya</h3>
              <div className="flex space-x-4">
                <a href="#" className="text-gray-400 hover:text-white text-xl">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#" className="text-gray-400 hover:text-white text-xl">
                  <i className="fab fa-telegram"></i>
                </a>
                <a href="#" className="text-gray-400 hover:text-white text-xl">
                  <i className="fab fa-discord"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-800 mt-8 pt-8 text-center text-gray-400">
            <p>© 2024 ZQ-Signal. Tüm hakları saklıdır.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;