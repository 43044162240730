// src/components/RiskStatus.js
import React from 'react';

const RiskIndicator = ({ rate, label, suggestionRange, isLeft = true }) => {
  const getTextColor = () => {
    if (rate >= 25) return 'text-red-600';
    if (rate >= 20) return 'text-yellow-600';
    return 'text-green-600';
  };

  const getBgColor = () => {
    if (rate >= 25) return 'bg-red-50';
    if (rate >= 20) return 'bg-yellow-50';
    return 'bg-green-50';
  };

  return (
    <div className={`flex-1 rounded-lg px-4 py-2 ${getBgColor()} ${isLeft ? 'mr-1' : 'ml-1'}`}>
      <div className="flex items-center justify-between text-sm text-gray-600 mb-0.5">
        <div className="flex items-center">
          <span>{label}</span>
          <span className="text-gray-400 ml-2">{suggestionRange}</span>
        </div>
      </div>
      <div className={`text-2xl font-bold ${getTextColor()} text-center`}>
        %{rate.toFixed(2)}
      </div>
    </div>
  );
};

const RiskStatus = ({ walletInfo }) => {
  const imRate = parseFloat(walletInfo?.accountIMRate || 0) * 100;
  const mmRate = parseFloat(walletInfo?.accountMMRate || 0) * 100;

  return (
    <div className="mt-1.5 bg-[rgb(231,_250,_255)] rounded-lg">
      <div className="flex p-1">
        <RiskIndicator 
          rate={imRate}
          label="IM Rate"
          suggestionRange="<%20"
          isLeft={true}
        />
        <RiskIndicator 
          rate={mmRate}
          label="MM Rate"
          suggestionRange="<%8"
          isLeft={false}
        />
      </div>
    </div>
  );
};

export default RiskStatus;