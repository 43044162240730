import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase/config';
import HCaptcha from '@hcaptcha/react-hcaptcha';

function SignUp() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: ''
  });

  // generateUserId fonksiyonu
  const generateUserId = async (name) => {
    const cleanName = name.toLowerCase()
      .replace(/\s+/g, '')
      .replace(/ğ/g, 'g')
      .replace(/ü/g, 'u')
      .replace(/ş/g, 's')
      .replace(/ı/g, 'i')
      .replace(/ö/g, 'o')
      .replace(/ç/g, 'c');

    let userId;
    let isUnique = false;
    
    while (!isUnique) {
      const uniqueCode = Math.floor(1000 + Math.random() * 9000);
      userId = `${cleanName}_${uniqueCode}`;
      
      const docRef = doc(db, 'users', userId);
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) {
        isUnique = true;
      }
    }
    
    return userId;
  };

  // handleGoogleLogin fonksiyonu
  const handleGoogleLogin = async () => {
    setLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      
      if (userCredential.user) {
        const userId = await generateUserId(userCredential.user.displayName || 'user');
        
        await setDoc(doc(db, 'users', userId), {
          profile: {
            email: userCredential.user.email,
            name: userCredential.user.displayName,
            userId: userId,
            authId: userCredential.user.uid,
            createdAt: new Date(),
            lastLogin: new Date(),
            membership: {
              isActive: false,
              startDate: null,
              endDate: null,
              type: null
            }
          }
        });

        navigate('/membership');
      }
    } catch (error) {
      console.error('Google signup error:', error);
      setError('Google ile kayıt sırasında bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!token) {
      setError('Lütfen robot olmadığınızı doğrulayın.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const userId = await generateUserId(formData.name);
      
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      await setDoc(doc(db, 'users', userId), {
        profile: {
          email: formData.email,
          name: formData.name,
          userId: userId,
          authId: userCredential.user.uid,
          createdAt: new Date(),
          lastLogin: new Date(),
          membership: {
            isActive: false,
            startDate: null,
            endDate: null,
            type: null
          }
        }
      });

      navigate('/membership');
      
    } catch (err) {
      console.error('Signup error:', err);
      const errorMessage = {
        'auth/email-already-in-use': 'Bu email adresi zaten kullanımda.',
        'auth/invalid-email': 'Geçersiz email adresi.',
        'auth/operation-not-allowed': 'Email/şifre ile kayıt şu anda devre dışı.',
        'auth/weak-password': 'Şifre çok zayıf. En az 6 karakter kullanın.',
      }[err.code] || 'Kayıt sırasında bir hata oluştu.';
      
      setError(errorMessage);
      if (captchaRef.current) {
        captchaRef.current.resetCaptcha();
        setToken(null);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-blue-900 flex flex-col items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-8 w-full max-w-md">
      <div className="flex flex-col items-center mb-6">
          <img 
            src="/images/zq_main.png" 
            alt="ZQ Signal" 
            className="w-36 mb-6"  // Genişliği ve alt boşluğu ayarlayabilirsiniz
          />
          <h2 className="text-2xl font-bold text-center">
          Yeni Hesap Oluştur
          </h2>
          </div>

        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">
            {error}
          </div>
        )}

        <button
          onClick={handleGoogleLogin}
          className="w-full py-2 px-4 mb-6 rounded-md text-white font-medium bg-red-600 hover:bg-red-700 flex items-center justify-center gap-2"
        >
          <i className="fab fa-google"></i>
          Google ile Üye Ol
        </button>

        <div className="relative mb-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">veya</span>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Ad Soyad
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Şifre
            </label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
              minLength={6}
            />
          </div>

          <div className="flex justify-center my-4">
            <HCaptcha
              ref={captchaRef}
              sitekey="240851ba-fda8-4c94-9806-0e2e11460692"
              onVerify={setToken}
              onExpire={() => setToken(null)}
            />
          </div>

          <button
            type="submit"
            disabled={loading || !token}
            className={`w-full py-2 px-4 rounded-md text-white font-medium ${
              loading || !token
                ? 'bg-blue-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700'
            }`}
          >
            {loading ? 'Kaydediliyor...' : 'Kayıt Ol'}
          </button>

          <div className="text-center mt-4">
            <button
              type="button"
              onClick={() => navigate('/')}
              className="text-blue-600 hover:text-blue-800"
            >
              Ana Sayfaya Dön
            </button>
          </div>

          <div className="text-sm text-center mt-6">
                Zaten hesabınız var mı? 
                <button
                  onClick={() => navigate('/login')}
                  className="text-blue-600 hover:text-blue-800 ml-1 font-semibold"
                >
                  Giriş Yapın
                </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUp;