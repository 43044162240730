import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDfxjRkF3YeUeFqdEStc8TWunxDsfQfREY",
  authDomain: "zq-signal.firebaseapp.com",
  projectId: "zq-signal",
  storageBucket: "zq-signal.appspot.com",
  messagingSenderId: "968175391599",
  appId: "1:968175391599:web:0e8ad3d1c5b7b73ef6ce9a",
  measurementId: "G-FR3XFZR1CB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and Auth
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
export default app;