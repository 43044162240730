import React, { useState } from 'react';
import WalletStatsModal from './WalletStatsModal';

const HourlyStatsModal = ({ isOpen, onClose, stats }) => {
  if (!isOpen) return null;

  const formatNumber = (num) => {
    if (!num || isNaN(num)) return '0.00';
    return parseFloat(num).toFixed(2);
  };

  // Toplam süre hesaplama ve formatlama
  const formatDuration = (hours) => {
    if (!hours || isNaN(hours)) return '0 saat';
    const roundedHours = Math.round(hours * 100) / 100;
    return `${roundedHours} saat`;
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div 
        className="bg-white rounded-lg p-6 w-full max-w-md shadow-xl"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-gray-800">Detaylı İstatistikler</h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ×
          </button>
        </div>

        <div className="space-y-4">
          {/* Genel İstatistikler */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-semibold text-gray-700 mb-2">Genel İstatistikler</h3>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-gray-600">Toplam Süre:</span>
                <span className="font-medium">{formatDuration(stats.totalHours)}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Toplam İşlem:</span>
                <span className="font-medium">{stats.totalTrades} işlem</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Toplam Kar/Zarar:</span>
                <span className={`font-medium ${stats.totalProfit >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                  ${formatNumber(stats.totalProfit)}
                </span>
              </div>
            </div>
          </div>

          {/* Saatlik Performans */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-semibold text-gray-700 mb-2">Saatlik Performans</h3>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-gray-600">Saatlik Ortalama Kar:</span>
                <span className={`font-medium ${(stats.totalProfit / stats.totalHours) >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                  ${formatNumber(stats.totalHours > 0 ? stats.totalProfit / stats.totalHours : 0)}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Saatlik İşlem:</span>
                <span className="font-medium">
                  {formatNumber(stats.avgTradesPerHour)} işlem
                </span>
              </div>
            </div>
          </div>

          {/* İşlem Performansı */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-semibold text-gray-700 mb-2">İşlem Performansı</h3>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-gray-600">İşlem Başına Kar:</span>
                <span className={`font-medium ${stats.avgProfitPerTrade >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                  ${formatNumber(stats.avgProfitPerTrade)}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Saatlik ROI:</span>
                <span className={`font-medium ${(stats.totalProfit / stats.totalHours) >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                  %{formatNumber((stats.totalProfit / stats.totalHours) * 100)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


// Ana Stats bileşenini güncelle
const Stats = ({ stats, hourlyStats, walletInfo, formatMoney }) => {
  const [showHourlyStats, setShowHourlyStats] = useState(false);
  const [showWalletStats, setShowWalletStats] = useState(false);

  if (!stats) return null; // Stats verisi henüz yüklenmediyse

  return (
    <div className="profit-summary">
      <table>
        <tbody>
          <tr
            onClick={() => setShowWalletStats(true)}
            style={{ cursor: 'pointer' }}
            className="hover:bg-[#e5f4ff] transition-colors"
          >
            <td>
              <i className="fas fa-dollar-sign"></i>
              <h4>Total:</h4>
            </td>
            <td>
              {formatMoney(stats.totalProfitLoss?.value)} - {stats.totalProfitLoss?.trades} trades
            </td>
          </tr>

          <tr style={{
            backgroundColor: (stats.unrealizedPnl?.value || 0) >= 0 
              ? 'rgba(34, 197, 94, 0.1)'
              : 'rgba(239, 68, 68, 0.1)'
          }}>
            <td>
              <i className="fas fa-chart-line"></i>
              <h4>uPNL:</h4>
            </td>
            <td>
              {formatMoney(stats.unrealizedPnl?.value)} - {stats.unrealizedPnl?.trades} trades
            </td>
          </tr>
          
          <tr>
            <td>
              <i className="fas fa-calendar-day"></i>
              <h4>Last Day:</h4>
            </td>
            <td>
              {formatMoney(stats.dailyProfit?.value)} - {stats.dailyProfit?.trades} trades
            </td>
          </tr>
          
          <tr
            onClick={() => setShowHourlyStats(true)}
            style={{ cursor: 'pointer' }}
            className="hover:bg-[#e5f4ff] transition-colors"
          >
            <td>
              <i className="fas fa-clock"></i>
              <h4>Hourly Avg:</h4>
            </td>
            <td>
              {formatMoney(stats.hourlyProfit?.value)} - {stats.hourlyProfit?.trades} trades/hour
            </td>
          </tr>
        </tbody>
      </table>

      <HourlyStatsModal 
        isOpen={showHourlyStats}
        onClose={() => setShowHourlyStats(false)}
        stats={hourlyStats}
        formatMoney={formatMoney}
      />

      <WalletStatsModal 
        isOpen={showWalletStats}
        onClose={() => setShowWalletStats(false)}
        walletInfo={walletInfo}
        formatMoney={formatMoney}
      />
    </div>
  );
};
export default Stats;