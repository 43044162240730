import React, { useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { 
  signInWithEmailAndPassword, 
  sendPasswordResetEmail, 
  signInWithPopup, 
  GoogleAuthProvider 
} from 'firebase/auth';
import { auth } from '../firebase/config';

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showResetForm, setShowResetForm] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);
  const [token, setToken] = useState(null);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const captchaRef = useRef(null);
  
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  // handleChange fonksiyonu eklendi
  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const failedAttempts = parseInt(localStorage.getItem('failedAttempts') || '0');
      
      if (failedAttempts >= 3) {
        if (!token) {
          setError('Lütfen robot olmadığınızı doğrulayın.');
          setLoading(false);
          return;
        }
      }

      await signInWithEmailAndPassword(auth, formData.email, formData.password);
      localStorage.setItem('failedAttempts', '0');
      navigate('/');
    } catch (err) {
      const currentFailed = parseInt(localStorage.getItem('failedAttempts') || '0');
      localStorage.setItem('failedAttempts', (currentFailed + 1).toString());

      const errorMessage = {
        'auth/user-not-found': 'Bu email adresiyle kayıtlı kullanıcı bulunamadı.',
        'auth/wrong-password': 'Hatalı şifre.',
        'auth/invalid-email': 'Geçersiz email adresi.',
        'auth/too-many-requests': 'Çok fazla başarısız giriş denemesi. Lütfen robot olmadığınızı doğrulayın.',
      }[err.code] || 'Giriş yapılırken bir hata oluştu.';
      
      setError(errorMessage);
      
      if (currentFailed + 1 >= 3) {
        setShowCaptcha(true);
        if (captchaRef.current) {
          captchaRef.current.resetCaptcha();
        }
      }
    } finally {
      setLoading(false);
    }
  };




  const handleGoogleLogin = async () => {
    setLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate('/');
    } catch (error) {
      console.error('Google login error:', error);
      setError('Google ile giriş sırasında bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setResetSuccess(false);

    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetSuccess(true);
      setError('');
    } catch (err) {
      const errorMessage = {
        'auth/user-not-found': 'Bu email adresiyle kayıtlı kullanıcı bulunamadı.',
        'auth/invalid-email': 'Geçersiz email adresi.',
      }[err.code] || 'Şifre sıfırlama maili gönderilirken bir hata oluştu.';
      
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  if (showResetForm) {
    return (
      <div className="min-h-screen bg-blue-900 flex flex-col items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-xl p-8 w-full max-w-md">
          <h2 className="text-2xl font-bold text-center mb-8">
            Şifre Sıfırlama
          </h2>

          {error && (
            <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">
              {error}
            </div>
          )}

          {resetSuccess && (
            <div className="mb-4 p-3 bg-green-100 text-green-700 rounded-md">
              Şifre sıfırlama bağlantısı email adresinize gönderildi.
            </div>
          )}

          <form onSubmit={handleResetPassword} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email Adresi
              </label>
              <input
                type="email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className={`w-full py-2 px-4 rounded-md text-white font-medium ${
                loading
                  ? 'bg-blue-400 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700'
              }`}
            >
              {loading ? 'Gönderiliyor...' : 'Şifre Sıfırlama Maili Gönder'}
            </button>

            <div className="text-center mt-4">
              <button
                type="button"
                onClick={() => setShowResetForm(false)}
                className="text-blue-600 hover:text-blue-800"
              >
                Giriş sayfasına dön
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }


  
  return (
    <div className="min-h-screen bg-blue-900 flex flex-col items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-8 w-full max-w-md">

      <div className="flex flex-col items-center mb-8">
          <img 
            src="/images/zq_main.png" 
            alt="ZQ Signal" 
            className="w-48 mb-3"  // Genişliği ve alt boşluğu ayarlayabilirsiniz
          />
          <h2 className="text-2xl font-bold text-center">
          Giriş Yap
          </h2>
          </div>

 

        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">
            {error}
          </div>
        )}

        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Şifre
            </label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

         {showCaptcha && (
    <div className="flex justify-center my-4">
      <HCaptcha
        ref={captchaRef}
        sitekey="240851ba-fda8-4c94-9806-0e2e11460692"
        onVerify={setToken}
        onExpire={() => setToken(null)}
      />
    </div>
  )}

          <button
            type="submit"
            disabled={loading || (showCaptcha && !token)}
            className={`w-full py-2 px-4 rounded-md text-white font-medium ${
              loading || (showCaptcha && !token)
                ? 'bg-blue-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700'
            }`}
          >
            {loading ? 'Giriş yapılıyor...' : 'Giriş Yap'}
          </button>
        </form>

        <div className="relative my-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">veya</span>
          </div>
        </div>

        <button
          onClick={handleGoogleLogin}
          className="w-full py-2 px-4 rounded-md text-white font-medium bg-red-600 hover:bg-red-700 flex items-center justify-center gap-2"
        >
          <i className="fab fa-google"></i>
          Google ile Giriş Yap
        </button>

        <div className="flex flex-col space-y-4 mt-6">
          <button
            type="button"
            onClick={() => setShowResetForm(true)}
            className="text-sm text-blue-600 hover:text-blue-800"
          >
            Şifremi Unuttum
          </button>
          
          <Link
            to="/signup"
            className="text-sm text-blue-600 hover:text-blue-800 text-center"
          >
            Yeni Hesap Oluştur
          </Link>

          <button
            type="button"
            onClick={() => navigate('/')}
            className="text-blue-600 hover:text-blue-800 flex items-center justify-center gap-1"
          >
            <i className="fas fa-arrow-left text-sm"></i>
            Ana Sayfaya Dön
          </button>
 
        </div>
      </div>
    </div>
  );
}

export default Login;