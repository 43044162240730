// pages/Membership.js
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db, auth } from '../firebase/config';
import { signOut } from 'firebase/auth';

const Membership = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentMembership, setCurrentMembership] = useState(null);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error("Çıkış hatası:", error);
    }
  };

  const PLANS = [
    {
      id: 'basic',
      name: 'Temel Plan',
      price: 29,
      duration: 30,
      period:'Ay',
      features: [ 'Temel Sinyaller',
        'Manuel Trading Desteği',
        'Telegram Grup Erişimi',
        'Email Desteği', 'Egitim Dokumanlari' ]
    },
    {
      id: 'profesyonel',
      name: 'Profesyonel Plan',
      price: 99,
      duration: 30,
      period:'Ay',
      features: [ 'Temel Plan Ozellikleri',  'Gelişmiş Sinyaller',
        'Otomatik Trading',
        'Otomatik DCA Stratejileri', 
        'Otomatik Hedge İşlem Koruması']
    },
    {
      id: 'profesyonel',
      name: 'Profesyonel Yillik Plan',
      price: 999,
      duration: 365,
      period:'Yil',
      features: [   'Gelişmiş Sinyaller',
        'Otomatik Trading',
        'Otomatik DCA Stratejileri', 
        'Otomatik Hedge İşlem Koruması', '2 ay ücretsiz']
    },
 
  ];



  useEffect(() => {
    checkMembershipStatus();
  }, []);

  const checkMembershipStatus = async () => {
    try {
      if (!auth.currentUser?.email) {
        navigate('/login');
        return;
      }

      const usersRef = collection(db, 'users');
      const q = query(usersRef, where("profile.email", "==", auth.currentUser.email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        setCurrentMembership(userData.profile.membership);
      }
    } catch (error) {
      console.error('Üyelik durumu kontrol hatası:', error);
      setError('Üyelik durumu kontrol edilirken bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

 

  const handlePayment = async (planId) => {
    try {
      if (!auth.currentUser?.email) {
        setError('Lütfen önce giriş yapın');
        return;
      }
  
      setLoading(true);
      const plan = PLANS.find(p => p.id === planId);
      
      // Form oluştur
      const form = document.createElement('form');
      form.method = 'post';
      form.action = 'https://www.coinpayments.net/index.php';
      form.target = '_blank';
  
      // Form alanlarını oluştur
      const formInputs = {
        cmd: '_pay_simple',
        reset: '1',
        merchant: 'a2a10f500ab540c47cc4476292d89e7a', // Test merchant ID
        currency: 'USDT',
        amountf: plan.price.toFixed(2),
        item_name: plan.name,
        item_desc: `ZQ-Signals ${plan.name} Subscription`,
        success_url: `${window.location.origin}/payment/success`,
        cancel_url: `${window.location.origin}/payment/cancel`,
        buyer_email: auth.currentUser.email
      };
  
      // Form input'larını oluştur ve ekle
      Object.entries(formInputs).forEach(([key, value]) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = value;
        form.appendChild(input);
      });
  
      // Submit butonu ekle
      const submitButton = document.createElement('input');
      submitButton.type = 'image';
      submitButton.src = 'https://www.coinpayments.net/images/pub/buynow.png';
      submitButton.alt = 'Buy Now with CoinPayments.net';
      form.appendChild(submitButton);
  
      // Kullanıcı dokümanını bul ve bekleyen ödeme bilgisini kaydet
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where("profile.email", "==", auth.currentUser.email));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        throw new Error('Kullanıcı profili bulunamadı');
      }
  
      const userDoc = querySnapshot.docs[0];
      await updateDoc(doc(db, 'users', userDoc.id), {
        'pendingPayment': {
          planId: plan.id,
          amount: plan.price,
          createdAt: new Date(),
          status: 'pending',
          invoiceId: Date.now().toString() // Benzersiz sipariş ID'si
        }
      });
  
      // Formu sayfaya ekle ve gönder
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
  
    } catch (error) {
      console.error('Ödeme başlatma hatası:', error);
      setError('Ödeme başlatılırken bir hata oluştu. Lütfen tekrar deneyin.');
    } finally {
      setLoading(false);
    }
  };



  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600 mx-auto"></div>
          <p className="mt-4 text-gray-600">Yükleniyor...</p>
        </div>
      </div>
    );
  }


  // Membership sayfası için güncellenmiş tasarım
return (
  <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
    {/* Header */}
    <header className="bg-transparent">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <img src="/images/logo.png" alt="ZQ-Signal" className="h-10 w-auto" />
            <span className="ml-3 text-2xl font-bold text-white">ZQ-Signal</span>
          </div>
          {auth.currentUser && (
            <div className="flex items-center gap-4">
              <span className="text-gray-300">
                {auth.currentUser.email}
              </span>
              <button 
                onClick={handleLogout}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
              >
                Çıkış Yap
              </button>
            </div>
          )}
        </div>
      </nav>
    </header>

    {/* Membership Content */}
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      {currentMembership?.isActive && (
        <div className="bg-green-500 bg-opacity-10 border border-green-500 rounded-lg p-4 mb-12">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <i className="fas fa-check-circle text-green-500 text-xl"></i>
            </div>
            <div className="ml-3">
              <h3 className="text-lg font-medium text-green-500">
                Aktif Üyeliğiniz: {currentMembership.type === 'monthly' ? 'Aylık' : 'Yıllık'} Plan
              </h3>
              <p className="mt-1 text-green-400">
                Bitiş Tarihi: {currentMembership.endDate && new Date(currentMembership.endDate.toDate()).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="text-center mb-16">
        <h2 className="text-4xl font-bold text-white mb-4">
          Üyelik Planları
        </h2>
        <p className="text-xl text-gray-300">
          Size en uygun planı seçin ve trading yolculuğunuza başlayın
        </p>
      </div>

      {error && (
        <div className="mb-8 p-4 bg-red-500 bg-opacity-10 border border-red-500 rounded-lg text-red-500">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {PLANS.map((plan) => (
          <div
            key={plan.id}
            className={`bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg overflow-hidden transform hover:-translate-y-1 transition-all duration-200 ${
              plan.id === 'monthly' ? 'ring-2 ring-blue-500' : ''
            }`}
          >
            <div className="p-8">
              <h3 className="text-xl font-semibold text-white mb-2">
                {plan.name}
              </h3>
              <div className="text-4xl font-bold text-white mb-6">
                ${plan.price}
                <span className="text-lg text-gray-400">/{plan.period}</span>
              </div>
              <ul className="space-y-4 mb-8">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-center text-gray-300">
                    <i className="fas fa-check text-green-500 mr-2"></i>
                    {feature}
                  </li>
                ))}
              </ul>
              <button
                onClick={() => handlePayment(plan.id)}
                disabled={loading}
                className={`w-full py-3 rounded-lg font-semibold ${
                  plan.id === 'monthly'
                    ? 'bg-blue-600 text-white hover:bg-blue-700'
                    : 'bg-gray-700 text-white hover:bg-gray-600'
                } transition-all transform hover:scale-105 ${
                  loading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {loading ? 'İşleniyor...' : 'Şimdi Başla'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);




};

export default Membership;