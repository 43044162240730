// components/TradingConfig.js
import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db, auth } from '../firebase/config';
import { useNavigate } from 'react-router-dom';

const TradingConfig = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [formData, setFormData] = useState({
    bybitApiKey: '',
    bybitApiSecret: '',
    isEnabled: false
  });

  const loadConfig = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser?.email) {
        console.log('Kullanıcı email bilgisi bulunamadı');
        navigate('/');
        return;
      }

      // Email'e göre kullanıcı dokümanını bul
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where("profile.email", "==", currentUser.email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log('Kullanıcı profili bulunamadı');
        setError('Kullanıcı profili bulunamadı');
        return;
      }

      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data();
      
      setFormData({
        bybitApiKey: userData.apiConfig?.bybit?.apiKey || '',
        bybitApiSecret: userData.apiConfig?.bybit?.apiSecret || '',
        isEnabled: userData.apiConfig?.bybit?.isEnabled || false
      });

    } catch (err) {
      console.error('Config yükleme hatası:', err);
      setError('Ayarlar yüklenirken bir hata oluştu.');
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (!user) {
        navigate('/');
      } else {
        loadConfig();
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const currentUser = auth.currentUser;
      if (!currentUser?.email) {
        throw new Error('Kullanıcı email bilgisi bulunamadı');
      }

      // Email'e göre kullanıcı dokümanını bul
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where("profile.email", "==", currentUser.email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        throw new Error('Kullanıcı profili bulunamadı');
      }

      const userDoc = querySnapshot.docs[0];
      await updateDoc(doc(db, 'users', userDoc.id), {
        'apiConfig.bybit': {
          apiKey: formData.bybitApiKey,
          apiSecret: formData.bybitApiSecret,
          isEnabled: formData.isEnabled
        }
      });

      setSuccess('API bilgileri başarıyla güncellendi');
      setTimeout(() => navigate('/'), 1500);

    } catch (err) {
      console.error('Kayıt hatası:', err);
      setError('Ayarlar kaydedilirken bir hata oluştu: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  if (!auth.currentUser) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto">
        <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
          <div className="flex items-center justify-between mb-8">
            <h2 className="text-2xl font-bold text-gray-900">
              Bybit API Ayarları
            </h2>
            <button
              onClick={() => navigate('/')}
              className="text-gray-600 hover:text-gray-800"
            >
              <i className="fas fa-times"></i>
            </button>
          </div>

          {error && (
            <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">
              {error}
            </div>
          )}

          {success && (
            <div className="mb-4 p-3 bg-green-100 text-green-700 rounded-md">
              {success}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Bybit API Key
              </label>
              <input
                type="text"
                name="bybitApiKey"
                value={formData.bybitApiKey}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Bybit API Secret
              </label>
              <input
                type="password"
                name="bybitApiSecret"
                value={formData.bybitApiSecret}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                required
              />
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                name="isEnabled"
                checked={formData.isEnabled}
                onChange={handleChange}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm text-gray-900">
                Trading'i Aktif Et
              </label>
            </div>

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => navigate('/')}
                className="flex-1 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                İptal
              </button>
              <button
                type="submit"
                disabled={loading}
                className={`flex-1 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                  loading
                    ? 'bg-indigo-400 cursor-not-allowed'
                    : 'bg-indigo-600 hover:bg-indigo-700'
                }`}
              >
                {loading ? 'Kaydediliyor...' : 'Kaydet'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TradingConfig;