import React from 'react';

const formatNumber = (value) => {
  if (!value) return "0.00";
  const num = parseFloat(value);
  if (isNaN(num)) return "0.00";
  return num.toFixed(2);
};

const WalletStatsModal = ({ isOpen, onClose, walletInfo }) => {
  if (!isOpen) return null;

  // Yeni data yapısına göre değerleri hazırla
  const totalWallet = parseFloat(walletInfo?.total_wallet_balance || 0);
  const availableBalance = parseFloat(walletInfo?.total_available_balance || 0);
  const totalEquity = parseFloat(walletInfo?.total_equity || 0);
  const cumulativePnl = parseFloat(walletInfo?.cumulative_pnl || 0);
  const unrealisedPnl = parseFloat(walletInfo?.unrealised_pnl || 0);

  const availablePercentage = totalWallet > 0 
    ? ((availableBalance / totalWallet) * 100).toFixed(2)
    : "0.00";
  const isLowBalance = parseFloat(availablePercentage) < 80;

  // USDT Balance bilgilerini al
  const usdtBalance = walletInfo?.usdt_balance || {};

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      onClick={onClose}
    >
      <div 
        className="bg-white rounded-xl w-full max-w-sm shadow-2xl"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-semibold text-gray-800">Cüzdan Özeti</h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 p-2"
          >
            ×
          </button>
        </div>

        <div className="p-4 space-y-4">
          {/* Ana Bakiye Kartı */}
          <div className="bg-blue-50 rounded-lg p-4">
            <div className="space-y-3">
              <div>
                <div className="text-sm text-gray-500 mb-1">Toplam Bakiye</div>
                <div className="text-2xl font-bold">${formatNumber(totalWallet)}</div>
              </div>

              <div>
                <div className="text-sm text-gray-500 mb-1">Kullanılabilir Bakiye</div>
                <div className="text-2xl font-bold text-green-600">
                  ${formatNumber(availableBalance)}
                </div>
              </div>

              {/* Yüzde ve Progress Bar */}
              <div className="pt-2">
                <div className="text-3xl font-bold text-blue-600 text-center mb-2">
                  %{availablePercentage}
                </div>
                <div className="text-sm text-gray-500 text-center mb-2">
                  Kullanılabilir Oran
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div 
                    className={`h-2 rounded-full transition-all duration-300 ${
                      isLowBalance ? 'bg-red-500' : 'bg-blue-600'
                    }`}
                    style={{ width: `${availablePercentage}%` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          {/* USDT Detayları */}
          <div className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-gray-500 mb-3">USDT Detayları</h3>
            <div className="space-y-2 text-sm">
              <div className="flex justify-between items-center">
                <span className="text-gray-500">Wallet Balance</span>
                <span className="font-medium">
                  ${formatNumber(usdtBalance.wallet_balance)}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-500">Available to Withdraw</span>
                <span className="font-medium">
                  ${formatNumber(usdtBalance.available_to_withdraw)}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-500">Unrealised PNL</span>
                <span className={`font-medium ${
                  parseFloat(usdtBalance.unrealised_pnl) >= 0 ? 'text-green-600' : 'text-red-600'
                }`}>
                  ${formatNumber(usdtBalance.unrealised_pnl)}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-500">Equity</span>
                <span className="font-medium">
                  ${formatNumber(usdtBalance.equity)}
                </span>
              </div>
            </div>
          </div>

          {/* Genel PNL Bilgileri */}
          <div className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-gray-500 mb-3">PNL Özeti</h3>
            <div className="space-y-2 text-sm">
              <div className="flex justify-between items-center">
                <span className="text-gray-500">Toplam Equity</span>
                <span className="font-medium">${formatNumber(totalEquity)}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-500">Toplam Unrealised PNL</span>
                <span className={`font-medium ${unrealisedPnl >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                  ${formatNumber(unrealisedPnl)}
                </span>
              </div>
              <div className="flex justify-between items-center pt-2 border-t border-gray-200 mt-2">
                <span className="text-gray-500">Cumulative PNL</span>
                <span className={`font-medium ${cumulativePnl >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                  ${formatNumber(cumulativePnl)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletStatsModal;