 

 import React, { useState, useEffect, useCallback } from 'react';
 import { 
  collection, 
  onSnapshot, 
  doc, 
  updateDoc, 
  deleteDoc, 
  query, 
  orderBy,
  where,
  serverTimestamp,
  getDocs
 
} from 'firebase/firestore';

 import { useNavigate } from 'react-router-dom';
 import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
 import { db, auth } from '../firebase/config';
 import { notificationManager } from '../utils/notification';
 import Stats from './Stats';
 import WalletStatsModal from './WalletStatsModal';
 import RiskStatus from './RiskStatus';
 

 // Para birimi state'i ve sembolleri
const CURRENCY_SYMBOLS = {
  USD: '$',
  TRY: '₺'
};


 const TradingDashboard = () => {
   // Base states
   const navigate = useNavigate();
   const [user, setUser] = useState(null);
   const [positions, setPositions] = useState([]);

   const [activeTab, setActiveTab] = useState('closed');
   const [selectedPosition, setSelectedPosition] = useState(null);
   const [coinLogos, setCoinLogos] = useState({}); // Bunu ekliyoruz
   //const [selectedPositionId, setSelectedPositionId] = useState(null);
   const [showAllPositions, setShowAllPositions] = useState(false);
   const [membershipChecked, setMembershipChecked] = useState(false);
   //const [notifiedPositionKeys] = useState(new Set());
   const [authChecked, setAuthChecked] = useState(false);
   const [authLoading, setAuthLoading] = useState(true);
   const [authError, setAuthError] = useState(null);

   const [loading, setLoading] = useState(true);
   const [isInitialized, setIsInitialized] = useState(false);

   const [selectedPositionMessages, setSelectedPositionMessages] = useState([]);
 
   // Stats and modal states
   const [showWalletStats, setShowWalletStats] = useState(false);
   const [pnlUpdateModalOpen, setPnlUpdateModalOpen] = useState(false);
   const [newPnlValue, setNewPnlValue] = useState('');
   const [hourlyStats, setHourlyStats] = useState({
     totalHours: 0,
     totalProfit: 0,
     totalTrades: 0,
     avgProfitPerTrade: 0,
     avgTradesPerHour: 0
   });
   const [stats, setStats] = useState({
     totalProfitLoss: "0.00",
     totalTrades: 0,
     dailyProfit: "0.00",
     dailyTrades: 0,
     hourlyProfit: "0.00",
     hourlyTrades: "0.00"
   });
 
   // Wallet state
    // Wallet state tanımlamasını güncelle
  const [walletData, setWalletData] = useState({
    total_wallet_balance: "0",
    total_available_balance: "0",
    total_equity: "0",
    total_margin_balance: "0",
    cumulative_pnl: "0",
    unrealised_pnl: "0",
    accountIMRate:"0",
    accountMMRate:"0",
    usdt_balance: {
      wallet_balance: "0",
      available_to_withdraw: "0",
      unrealised_pnl: "0",
      equity: "0"
    }
  });

  const [exchangeRate, setExchangeRate] = useState(() => {
    // Başlangıçta localStorage'dan son kuru almayı dene
    const lastRate = localStorage.getItem('last_exchange_rate');
    return lastRate ? parseFloat(lastRate) : 35;
  });





  // Para birimi state'i
const [currency, setCurrency] = useState(() => {
  const saved = localStorage.getItem('preferred_currency');
  return saved || 'USD';
});

 


 


 
// formatMoney util fonksiyonu güncellemesi
const formatMoney = useCallback((value, includeCurrency = true) => {
  if (!value || isNaN(value)) return '0.00';
  
  const numValue = parseFloat(value);
  // exchangeRate'i burada kullanıyoruz
  const converted = currency === 'TRY' ? numValue * exchangeRate : numValue;
  const isNegative = converted < 0;
  const absValue = Math.abs(converted);
  
  const formatted = new Intl.NumberFormat('tr-TR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(absValue);
  
  if (!includeCurrency) return formatted;
  
  return isNegative 
    ? `-${formatted}${CURRENCY_SYMBOLS[currency]}`
    : `${formatted}${CURRENCY_SYMBOLS[currency]}`;
}, [currency, exchangeRate]); // exchangeRate'i dependency array'e ekledik



// Header'daki para birimi toggle butonu güncellemesi
const CurrencyToggle = () => (
  <button
    onClick={() => {
      const newCurrency = currency === 'USD' ? 'TRY' : 'USD';
      setCurrency(newCurrency);
      localStorage.setItem('preferred_currency', newCurrency);
    }}
    className="px-3 py-1 rounded bg-indigo-600 text-white text-sm font-semibold hover:bg-indigo-700 transition-colors flex items-center gap-1"
  >
    <span>{CURRENCY_SYMBOLS[currency]}</span>
    <span className="text-xs">({currency})</span>
  </button>
);
 
const formatDate = (timestamp) => {
  if (!timestamp) return 'N/A';
  
  try {
    // Eğer timestamp number olarak geliyorsa
    if (typeof timestamp === 'number') {
      return new Date(timestamp).toLocaleString();
    }
    
    // Eğer timestamp string olarak geliyorsa
    if (typeof timestamp === 'string') {
      return new Date(timestamp).toLocaleString();
    }
    
    // Eğer timestamp firebase timestamp objesi olarak geliyorsa
    if (timestamp?.seconds) {
      return new Date(timestamp.seconds * 1000).toLocaleString();
    }
    
    return 'N/A';
  } catch (error) {
    console.error('Date formatting error:', error);
    return 'N/A';
  }
};


const processTimestamp = (timestamp) => {
  if (!timestamp) return null;
  try {
    // Eğer timestamp obje ise (Firestore Timestamp)
    if (timestamp?.seconds) {
      return timestamp.seconds * 1000;
    }
    
    // Eğer timestamp number ise
    if (typeof timestamp === 'number') {
      // Unix timestamp saniye cinsinden ise 1000 ile çarp
      return timestamp.toString().length === 10 ? timestamp * 1000 : timestamp;
    }
    
    // Eğer string ise
    if (typeof timestamp === 'string') {
      return new Date(timestamp).getTime();
    }
    
    return null;
  } catch (error) {
    console.error('Timestamp process error:', error);
    return null;
  }
};

  const fetchPositionMessages = useCallback(async (userId, positionId) => {
    if (!userId || !positionId) return;

    try {
      const messagesRef = collection(db, 'users', userId, 'positions', positionId, 'messages');
      const messagesQuery = query(messagesRef, orderBy('timestamp', 'desc'));

      const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        const messages = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          content: doc.data().content || {},
          timestamp: doc.data().timestamp || null
        }));
        setSelectedPositionMessages(messages);
      });

      return unsubscribe;
    } catch (error) {
      console.error('Error fetching messages:', error);
      setSelectedPositionMessages([]);
    }
  }, []);
 
   const [isSoundEnabled, setIsSoundEnabled] = useState(() => {
     const savedPreference = localStorage.getItem('soundEnabled');
     return savedPreference ? JSON.parse(savedPreference) : true;
   });
 

   
     // Update handlePositionClick to fetch messages
  const handlePositionClick = useCallback((position, key) => {
    setSelectedPosition(position);
    //setSelectedPositionId(key);
    setPnlUpdateModalOpen(false);
    
    // Fetch messages when a position is selected
    if (user?.matchedUserId && key) {
      fetchPositionMessages(user.matchedUserId, key);
    }
  }, [user, fetchPositionMessages]);


  const getShortId = (fullId) => {
    if (!fullId) return '';
    const parts = fullId.split('_');
    return parts.length > 1 ? parts.pop() : fullId;
  };
  ///******************************************************************* */

  // Define renderMessages
  const renderMessages = useCallback((messages) => {
    const getMessageBackground = (type) => {
      switch (type) {
        case 'OPEN':
          return 'bg-blue-50';
        case 'UPDATE':
          return 'bg-amber-50';
        case 'CLOSE':
          return 'bg-gray-50';
        default:
          return 'bg-white';
      }
    };
  
    const formatNumber = (num) => {
      if (!num || isNaN(num)) return '0.00';
      return parseFloat(num).toFixed(2);
    };
  
    const getMessageContent = (msg) => {
      const { type, description, data } = msg;
      
      switch (type) {
        case 'OPEN':
          if (!data) return 'Pozisyon açıldı';
          const openEntries = data.entries || {};
          const openSizes = data.sizes || {};
          
          if (data.side === 'both') {
            return `Hedge pozisyon açıldı: Long ${formatNumber(openSizes.long)} @ ${formatNumber(openEntries.long)} | Short ${formatNumber(openSizes.short)} @ ${formatNumber(openEntries.short)}`;
          }
          return `Pozisyon açıldı: ${data.side === 'long' ? 'Long' : 'Short'} ${formatNumber(data.side === 'long' ? openSizes.long : openSizes.short)} @ ${formatNumber(data.side === 'long' ? openEntries.long : openEntries.short)}`;
  
        case 'UPDATE':
          if (!data) return description || 'Pozisyon güncellendi';
          const changes = data.sizes?.changes || {};
          
          const longChange = formatNumber(changes.long);
          const shortChange = formatNumber(changes.short);
          
          if (changes.long && changes.short) {
            return `Hedge pozisyon güncellendi: Long ${longChange > 0 ? '+' : ''}${longChange} | Short ${shortChange > 0 ? '+' : ''}${shortChange}`;
          } else if (changes.long) {
            return `Long pozisyon güncellendi: ${longChange > 0 ? '+' : ''}${longChange}`;
          } else if (changes.short) {
            return `Short pozisyon güncellendi: ${shortChange > 0 ? '+' : ''}${shortChange}`;
          }
          return description || 'Pozisyon güncellendi';
  
        case 'CLOSE':
          return description || 'Pozisyon kapatıldı';
  
        default:
          return description || '';
      }
    };
  
    const formatDate = (timestamp) => {
      if (!timestamp) return 'N/A';
      const date = new Date(timestamp);
      return date.toLocaleTimeString('tr-TR', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
    };
  
    return messages
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
      .map(msg => {
        const messageContent = getMessageContent(msg);
        if (!messageContent) return null;
  
        return (
          <tr 
            key={msg.messageId} 
            className={`${getMessageBackground(msg.type)} transition-colors hover:bg-opacity-80`}
          >
            <td className="px-4 py-2 text-sm text-gray-600 whitespace-nowrap">
              {formatDate(msg.timestamp)}
            </td>
            <td className="px-4 py-2">
              <div className="text-sm text-gray-800">
                {messageContent}
              </div>
            </td>
          </tr>
        );
      })
      .filter(Boolean);
  }, []);

   const toggleSound = useCallback(async () => {
     const isEnabled = notificationManager.toggleSound();
     setIsSoundEnabled(isEnabled);
     
     if (isEnabled) {
       await notificationManager.playSound();
       notificationManager.showNotification('Ses Sistemi', {
         body: 'Ses sistemi aktif edildi!'
       });
     }
   }, []);
 

 

   const calculateStats = useCallback((positionsData) => {
    const now = new Date();
    const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    
    const closedPositions = positionsData.filter(pos => pos.status === 'closed');
    const openPositions = positionsData.filter(pos => pos.status === 'open');
  
    // PNL Hesaplamaları
    const realizedPnl = closedPositions.reduce((sum, pos) => {
      const pnlValue = parseFloat(pos.pnl || 0);
      return !isNaN(pnlValue) ? sum + pnlValue : sum;
    }, 0);
  
    const unrealizedPnl = openPositions.reduce((sum, pos) => {
      const pnlValue = parseFloat(pos.pnl || 0);
      return !isNaN(pnlValue) ? sum + pnlValue : sum;
    }, 0);

    // Timestamp işleme fonksiyonu
    const processTimestamp = (timestamp) => {
      try {
        if (!timestamp) return null;
        
        // Eğer timestamp sayı ise
        if (typeof timestamp === 'number') {
          // Unix timestamp saniye cinsinden ise 1000 ile çarp
          return timestamp.toString().length === 10 ? timestamp * 1000 : timestamp;
        }
        
        // Eğer string ise
        if (typeof timestamp === 'string') {
          return new Date(timestamp).getTime();
        }
        
        return null;
      } catch (error) {
        console.error('Timestamp process error:', error);
        return null;
      }
    };
  
    // Today's positions
    const todayClosedPositions = closedPositions.filter(position => {
      const closeTime = processTimestamp(position.closed_timestamp || position.timestamp);
      return closeTime && closeTime >= startOfToday.getTime();
    });
  
    const dailyProfit = todayClosedPositions.reduce((sum, pos) => {
      const pnlValue = parseFloat(pos.pnl || 0);
      return !isNaN(pnlValue) ? sum + pnlValue : sum;
    }, 0);
  
    // Saat hesaplaması için timestamp kontrolü ve düzeltmesi
    let hourlyStatsData = {
      totalHours: 0,
      totalProfit: realizedPnl,
      totalTrades: closedPositions.length,
      avgProfitPerTrade: closedPositions.length > 0 ? realizedPnl / closedPositions.length : 0,
      avgTradesPerHour: 0
    };
  
    if (closedPositions.length >= 2) {
      const timestamps = closedPositions
        .map(pos => processTimestamp(pos.timestamp))
        .filter(ts => ts !== null)
        .sort((a, b) => a - b);

      if (timestamps.length >= 2) {
        const firstTime = timestamps[0];
        const lastTime = timestamps[timestamps.length - 1];
        const diffHours = Math.max(1, (lastTime - firstTime) / (1000 * 60 * 60));
        
   
  
        hourlyStatsData = {
          totalHours: diffHours,
          totalProfit: realizedPnl,
          totalTrades: closedPositions.length,
          avgProfitPerTrade: realizedPnl / closedPositions.length,
          avgTradesPerHour: closedPositions.length / diffHours
        };
      }
    }
  
    // Stats güncelleme
    setHourlyStats(hourlyStatsData);
    setStats({
      totalProfitLoss: { value: realizedPnl, trades: closedPositions.length },
      unrealizedPnl: { value: unrealizedPnl, trades: openPositions.length },
      dailyProfit: { value: dailyProfit, trades: todayClosedPositions.length },
      hourlyProfit: {
        value: hourlyStatsData.totalHours > 0 ? (hourlyStatsData.totalProfit / hourlyStatsData.totalHours) : 0,
        trades: hourlyStatsData.avgTradesPerHour.toFixed(1)
      }
    });
  
    //console.log('Calculated hourly stats:', hourlyStatsData);
}, []);
 
 

   const handlePnlUpdate = useCallback(async () => {
    if (!selectedPosition?.id || !user?.matchedUserId) {
      console.error('Position ID veya user ID bulunamadı');
      return;
    }
  
    try {
      const positionRef = doc(db, 'users', user.matchedUserId, 'positions', selectedPosition.id);
      
      await updateDoc(positionRef, {
        pnl: newPnlValue,
        pnl_percentage: selectedPosition.entry_price ? 
          ((parseFloat(newPnlValue) / parseFloat(selectedPosition.entry_price)) * 100).toFixed(2) : 
          "0",
        last_updated: serverTimestamp()
      });
  
      console.log('PNL güncellendi:', newPnlValue);
      setPnlUpdateModalOpen(false);
      setNewPnlValue('');
    } catch (error) {
      console.error('PNL güncelleme hatası:', error);
      alert('PNL güncellenirken bir hata oluştu');
    }
  }, [selectedPosition, user, newPnlValue]);






   const handleLogin = useCallback(async () => {
    try {
      setAuthLoading(true);
      setAuthError(null);
      
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      
    } catch (error) {
      console.error("Login hatası:", error);
      setAuthError(error.message);
      setAuthLoading(false);
      alert("Giriş başarısız oldu: " + error.message);
    }
  }, []);
  
  const handleLogout = useCallback(async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Çıkış hatası:", error);
    }
  }, []);
  

// Logo getirme fonksiyonu
const getCoinLogo = useCallback((symbol = '') => {
  try {
    let baseSymbol = symbol
      .replace(/^\d+/, '') // Baştaki sayıları kaldır
      .replace(/\s*\([^)]*\)\s*/, '') // Parantez içini kaldır
      .replace(/USDT$|BUSD$|USD$/, '') // Sondaki market bilgisini kaldır
      .trim()
      .toUpperCase(); // Boşlukları kaldır ve büyük harfe çevir

    // console.log('Processing symbol:', symbol, 'Base symbol:', baseSymbol);
    // console.log('Available logos:', Object.keys(coinLogos));
    
    return coinLogos[baseSymbol] || '/images/default-coin.png';
  } catch (error) {
    console.error('Logo getirme hatası:', error, 'Symbol:', symbol);
    return '/images/default-coin.png';
  }
}, [coinLogos]);

 



 


  const INITIAL_POSITION_COUNT = 7;

const getFilteredPositions = useCallback(() => {
  const filteredByTab = positions.filter(position => position.status === activeTab);
  if (!showAllPositions) {
    return filteredByTab.slice(0, INITIAL_POSITION_COUNT);
  }
  return filteredByTab;
}, [positions, activeTab, showAllPositions]);

const renderShowMoreButton = useCallback(() => {
  const filteredPositions = positions.filter(position => position.status === activeTab);
  if (filteredPositions.length > INITIAL_POSITION_COUNT && !showAllPositions) {
    return (
      <button 
        onClick={() => setShowAllPositions(true)}
        className="show-more-button"
      >
        Tümünü Göster ({filteredPositions.length} pozisyon)
      </button>
    );
  }
  return null;
}, [positions, activeTab, showAllPositions]);

const handleDeletePosition = useCallback(async (positionId) => {
  if (!user?.matchedUserId || !positionId) return;

  if (window.confirm("Bu pozisyonu silmek istediğinize emin misiniz?")) {
    setSelectedPosition(null);
    try {
      const positionRef = doc(db, 'users', user.matchedUserId, 'positions', positionId);
      await deleteDoc(positionRef);
      console.log("Pozisyon silindi:", positionId);
    } catch (error) {
      console.error("Silme işlemi sırasında hata oluştu:", error);
      alert("Silme işlemi başarısız oldu: " + error.message);
    }
  }
}, [user]);


 

// Exchange rate effect - update every 3 hours
useEffect(() => {
  const REFRESH_INTERVAL = 3 * 60 * 60 * 1000; // 3 hours in milliseconds
  let timeoutId;

  const fetchBybitRate = async () => {
    try {
      // Check if enough time has passed since last update
      const lastUpdateTime = localStorage.getItem('last_exchange_rate_time');
      const currentTime = Date.now();
      
      if (lastUpdateTime && (currentTime - parseInt(lastUpdateTime)) < REFRESH_INTERVAL) {
        // If not enough time has passed, use cached rate
        const cachedRate = localStorage.getItem('last_exchange_rate');
        if (cachedRate) {
          setExchangeRate(parseFloat(cachedRate));
          return;
        }
      }

      const response = await fetch('https://api.bybit.com/v5/market/tickers?category=spot&symbol=USDTTRY');
      const data = await response.json();
      
      if (data.retCode === 0 && data.result.list?.[0]?.lastPrice) {
        const rate = parseFloat(data.result.list[0].lastPrice);
        setExchangeRate(rate);
        localStorage.setItem('last_exchange_rate', rate.toString());
        localStorage.setItem('last_exchange_rate_time', currentTime.toString());
        console.log('Exchange rate updated:', rate);
      }
    } catch (error) {
      console.error('Failed to fetch exchange rate:', error);
      // On error, use cached rate if available
      const cachedRate = localStorage.getItem('last_exchange_rate');
      if (cachedRate) {
        setExchangeRate(parseFloat(cachedRate));
      }
    }
  };

  // Initial fetch
  fetchBybitRate();
  
  // Set up periodic refresh
  timeoutId = setInterval(fetchBybitRate, REFRESH_INTERVAL);
  
  return () => {
    if (timeoutId) {
      clearInterval(timeoutId);
    }
  };
}, []); // Empty dependency array - only run once when component mounts




  // Logo yükleme effect'i
  useEffect(() => {
    const loadLogos = async () => {
      try {
        const defaultLogos = {}; // Varsayılan logoları burada tanımlayabiliriz
        setCoinLogos(defaultLogos);
        
        const response = await fetch('/logos.json');
        if (!response.ok) {
          throw new Error('Logolar yüklenemedi');
        }
        const logos = await response.json();
        setCoinLogos(logos);
      } catch (error) {
        console.error('Logo yükleme hatası:', error);
        // Hata durumunda varsayılan logoları kullan
        setCoinLogos({});
      }
    };
  
    loadLogos();
  }, []);
  

  useEffect(() => {
    let isMounted = true;
    let authUnsubscribe = null;

    const initialize = async () => {
      try {
        authUnsubscribe = auth.onAuthStateChanged(async (currentUser) => {
          if (!isMounted) return;

          // Kullanıcı yoksa landing page'e yönlendir
          if (!currentUser) {
            setLoading(false);
            navigate('/');
            return;
          }

          try {
            const usersRef = collection(db, 'users');
            const q = query(usersRef, where("profile.email", "==", currentUser.email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
              const userDoc = querySnapshot.docs[0];
              const userData = userDoc.data();
              const membership = userData.profile?.membership;

              // Üyelik kontrolü
              if (!membership?.isActive || 
                  (membership.endDate && new Date(membership.endDate.toDate()) < new Date())) {
                if (isMounted) {
                  setLoading(false);
                  navigate('/membership');
                }
                return;
              }

              // Üyelik aktifse kullanıcı bilgilerini set et ve loading'i kapat
              if (isMounted) {
                setUser({
                  ...currentUser,
                  matchedUserId: userDoc.id
                });
                setLoading(false);
              }
            } else {
              // Kullanıcı profili bulunamadıysa landing page'e yönlendir
              if (isMounted) {
                await signOut(auth);
                setLoading(false);
                navigate('/');
              }
            }
          } catch (error) {
            console.error("Profile check error:", error);
            if (isMounted) {
              setLoading(false);
              navigate('/');
            }
          }
        });
      } catch (error) {
        console.error("Initialization error:", error);
        if (isMounted) {
          setLoading(false);
          navigate('/');
        }
      }
    };

    initialize();

    return () => {
      isMounted = false;
      if (authUnsubscribe) {
        authUnsubscribe();
      }
    };
  }, [navigate]);

// Positions subscription düzeltmesi
 
useEffect(() => {
  if (!user?.matchedUserId) return;

  const positionsRef = collection(db, 'users', user.matchedUserId, 'positions');
  const positionsQuery = query(positionsRef, orderBy('timestamp', 'desc'));
  
  let lastNotificationTime = Date.now();
  const NOTIFICATION_DELAY = 1000;
  const notifiedPositions = new Set();
  let lastPositions = new Map(); // Son pozisyonların durumunu takip etmek için

  const unsubscribe = onSnapshot(positionsQuery, (snapshot) => {
    const positionsArray = snapshot.docs.map(doc => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        timestamp: processTimestamp(data.timestamp) || Date.now(),
        closed_timestamp: processTimestamp(data.closed_timestamp)
      };
    }).sort((a, b) => {
      const aTime = a.status === 'closed' ? (a.closed_timestamp || a.timestamp) : a.timestamp;
      const bTime = b.status === 'closed' ? (b.closed_timestamp || b.timestamp) : b.timestamp;
      
      if (a.status === 'closed' && b.status === 'closed') {
        return bTime - aTime;
      } else if (a.status === 'closed') {
        return -1;
      } else if (b.status === 'closed') {
        return 1;
      } else {
        return bTime - aTime;
      }
    });

    // Bildirim kontrolü
    if (isSoundEnabled) {
      const currentTime = Date.now();
      
      positionsArray.forEach(position => {
        const previousStatus = lastPositions.get(position.id)?.status;
        
        // Pozisyon yeni kapandıysa ve daha önce bildirim gönderilmediyse
        if (position.status === 'closed' && 
            previousStatus === 'open' && 
            !notifiedPositions.has(position.id) && 
            (currentTime - lastNotificationTime) > NOTIFICATION_DELAY) {
          
          notifiedPositions.add(position.id);
          notificationManager.notifyPositionClose(position)
            .catch(error => console.error('Notification error:', error));
          lastNotificationTime = currentTime;
        }
      });

      // Son pozisyon durumlarını güncelle
      lastPositions = new Map(positionsArray.map(pos => [pos.id, { status: pos.status }]));
    }

    setPositions(prev => {
      if (JSON.stringify(prev) === JSON.stringify(positionsArray)) return prev;
      return positionsArray;
    });
    
    calculateStats(positionsArray);
  });

  return () => unsubscribe();
}, [user?.matchedUserId, isSoundEnabled, calculateStats]);

 
 // Wallet subscription'ı güncelle
useEffect(() => {
  if (!user?.matchedUserId) return;

  console.log("Wallet subscription başlatılıyor");
  const walletRef = doc(db, 'users', user.matchedUserId, 'wallet', 'current');
  
  const unsubscribe = onSnapshot(walletRef, (doc) => {
    if (doc.exists()) {
      const data = doc.data();
     // console.log("Wallet data alındı:", data);
      
      // Yeni data yapısına göre verileri dönüştür
      setWalletData({
        total_wallet_balance: data.total_wallet_balance || "0",
        total_available_balance: data.total_available_balance || "0",
        total_equity: data.total_equity || "0",
        total_margin_balance: data.total_margin_balance || "0",
        cumulative_pnl: data.cumulative_pnl || "0",
        unrealised_pnl: data.unrealised_pnl || "0",
        accountIMRate: data.accountIMRate|| "0",
        accountMMRate: data.accountMMRate|| "0",
        usdt_balance: data.usdt_balance || {
          wallet_balance: "0",
          available_to_withdraw: "0",
          unrealised_pnl: "0",
          equity: "0"
        }
      });
    } else {
      console.log("Wallet dokümanı bulunamadı");
    }
  }, (error) => {
    console.error("Wallet subscription hatası:", error);
  });

  return () => unsubscribe();
}, [user]);
 


   // Loading durumunda spinner göster
   if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600 mx-auto"></div>
          <p className="mt-4 text-gray-600">Yükleniyor...</p>
        </div>
      </div>
    );
  }

  // Kullanıcı yoksa veya loading bittiyse null dön (zaten navigate ile yönlendirme yapıldı)
  if (!user) {
    return null;
  }



 

return (
<div>
  <div className="menu-bar">
    <div className="logo">
      <img src="/images/logo.png" alt="Logo" />
      <h1>ZQ-Signals</h1>
    </div>
    <div className="menu-controls flex items-center gap-3">
    <CurrencyToggle />

    <button
    onClick={() => navigate('/trading-config')}
    className="settings-button"
    title="Trading Ayarları"
  >
    <i className="fas fa-cog"></i>
  </button>

    <button 
      onClick={toggleSound}
      className="sound-toggle"
      title={isSoundEnabled ? 'Sesi Kapat' : 'Sesi Aç'}
    >
      <i className={`fas ${isSoundEnabled ? 'fa-volume-up' : 'fa-volume-mute'}`}></i>
    </button>
    <button className="exit-button" onClick={handleLogout}>
      Exit
    </button>
  </div>
  </div>

  <div className="tab-menu">
    <button 
      onClick={() => setActiveTab('open')}
      className={`tab-link ${activeTab === 'open' ? 'active' : ''}`}
    >
      Aktif ({positions.filter(p => p.status === 'open').length})
    </button>
    <button 
      onClick={() => setActiveTab('closed')}
      className={`tab-link ${activeTab === 'closed' ? 'active' : ''}`}
    >
      Kapali
    </button>
  </div>

  <div className="container mx-auto" style={{ maxWidth: '1000px', padding: '0 15px' }}>
    <div className="welcome-text">
      Hoş geldiniz, {user.displayName}
    </div>

    <Stats 
      stats={stats} 
      hourlyStats={hourlyStats} 
      walletInfo={walletData}
      formatMoney={formatMoney}   
    />

    <RiskStatus walletInfo={walletData} />

    <WalletStatsModal 
      isOpen={showWalletStats}
      onClose={() => setShowWalletStats(false)}
      walletInfo={walletData}
    />

    {/* Positions Cards */}
    <div className="position-cards mt-4">

    {getFilteredPositions().map(position => (
  <div 
    key={position.id}
    className="position-card"
    onClick={() => handlePositionClick(position, position.id)}
  >
    <img 
      src={getCoinLogo(position.symbol)}
      alt={position.symbol}
      className="w-8 h-8"
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = '/images/default-coin.png';
      }}
    />
    <div className="card-content">
      <div>
        <div className="card-symbol">{position.symbol}</div>
        <div className="card-strategy">
          {position.side && (
            <span className="position-type" style={{
              padding: '1px 8px',
              borderRadius: '3px',
              fontSize: '14px',
              fontWeight: 'bold',
              backgroundColor: position.side.toLowerCase() === 'long' ? 'rgba(34, 197, 94, 0.2)' : 
                            position.side.toLowerCase() === 'short' ? 'rgba(239, 68, 68, 0.2)' :
                            position.side.toLowerCase() === 'hedge' ? 'rgba(59, 130, 246, 0.2)' :
                            'rgba(156, 163, 175, 0.2)',
              color: position.side.toLowerCase() === 'long' ? '#22c55e' : 
                    position.side.toLowerCase() === 'short' ? '#ef4444' : 
                    position.side.toLowerCase() === 'hedge' ? '#3b82f6' : 
                    '#6b7280'
            }}>
              {position.side.toLowerCase() === 'long' ? 'L' : 
              position.side.toLowerCase() === 'short' ? 'S' : 
              position.side.toLowerCase() === 'hedge' ? 'H' : 
              '?'}
            </span>
          )} • {position.strategy || 'ZQTX003'}
        </div>
      </div>
      <div className="text-right">
        <div className={parseFloat(position.pnl || 0) >= 0 ? 'profit-positive' : 'profit-negative'}>
          {formatMoney(position.pnl)} • {parseFloat(position.pnl_percentage || 0).toFixed(2)}%
        </div>
        <div className="text-sm text-gray-500">
          {new Date(position.closed_timestamp || position.timestamp).toLocaleString()}
        </div>
      </div>
    </div>
  </div>
))}



  {renderShowMoreButton()}
</div>

    {/* Position Detail Modal */}
 
    {selectedPosition && (
  <div className="modal" onClick={() => {
    setSelectedPosition(null);
    setSelectedPositionMessages([]); 
  }}>
    <div className="modal-content" onClick={e => e.stopPropagation()}>
      {/* Header Content */}
      <div className="header-content">
        <div className="symbol-info flex items-center gap-3">
          {/* Coin Logo */}
          <img 
            src={getCoinLogo(selectedPosition.symbol)}
            alt={selectedPosition.symbol}
            className="w-8 h-8"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = '/images/default-coin.png';
            }}
          />
          <div>
            <div className="symbol-text font-bold">{selectedPosition.symbol}</div>
            <div className="strategy-text text-sm text-gray-500 flex items-center gap-2">
              <span>{selectedPosition.strategy || 'ZQTX005'}</span>
              <span className="text-xs text-gray-400">{getShortId(selectedPosition.id)}</span>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex items-center gap-2 mr-2">
          <button 
            onClick={(e) => {
              e.preventDefault();
              const cleanSymbol = selectedPosition.symbol
                .replace(/\s*\([^)]*\)\s*/, '')
                .replace(/(\w+)(USDT|USD|BUSD)$/, '$1$2.P');
              const tradingViewUrl = `https://tr.tradingview.com/chart?symbol=BYBIT%3A${encodeURIComponent(cleanSymbol)}`;
              window.open(
                tradingViewUrl,
                'tradingview',
                'width=1200,height=800,noopener,noreferrer'
              );
            }}
            className="bg-[#2962ff] text-white px-3 py-1 rounded text-sm font-semibold hover:bg-[#2962ff]/90 transition-colors"
          >
            TV
          </button>

          <button 
            onClick={(e) => {
              e.preventDefault();
              const cleanSymbol = selectedPosition.symbol
                .replace(/\s*\([^)]*\)\s*/, '')
                .replace(/(\w+)(USDT|USD|BUSD)$/, '$1$2');
              const bybitUrl = `https://www.bybit.com/trade/usdt/${cleanSymbol}`;
              window.open(
                bybitUrl,
                'bybit',
                'width=1200,height=800,noopener,noreferrer'
              );
            }}
            className="bg-[#f7a600] text-white px-3 py-1 rounded text-sm font-semibold hover:bg-[#f7a600]/90 transition-colors"
          >
            Bybit
          </button>

          <button
            onClick={(e) => {
              e.stopPropagation();
              setPnlUpdateModalOpen(true);
            }}
            className="bg-indigo-600 text-white px-3 py-1 rounded text-sm font-semibold hover:bg-indigo-700 transition-colors"
          >
            PNL
          </button>
        </div>

        <button 
          onClick={() => setSelectedPosition(null)} 
          className="text-gray-500 hover:text-gray-700 text-xl font-bold"
        >
          &times;
        </button>
      </div>

      {/* Messages Table */}
      <div className="max-h-[400px] overflow-y-auto mt-4 mb-4">
        <table className="w-full border-collapse">
          <thead className="sticky top-0 bg-white shadow-sm">
            <tr>
              <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600 w-32">
                Zaman
              </th>
              <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">
                İşlem Detayı
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {renderMessages(selectedPositionMessages)}
          </tbody>
        </table>
      </div>

      {/* Footer */}
      <div className="modal-footer border-t border-gray-200 pt-4 mt-auto">
      <div className="footer-info flex flex-col gap-2">
        <div className="text-left">
          <span className={`text-lg font-semibold ${parseFloat(selectedPosition.pnl) >= 0 ? 'text-green-500' : 'text-red-500'}`}>
            PNL: {parseFloat(selectedPosition.pnl).toFixed(2)}
          </span>
        </div>
          <div className="text-center">
            <span className="text-sm text-gray-600">
              Last Update: {formatDate(selectedPosition.lastUpdateTime)}
            </span>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button
            className="bg-red-500 text-white px-4 py-2 rounded text-sm font-semibold hover:bg-red-600 transition-colors"
            onClick={() => handleDeletePosition(selectedPosition.id)}
          >
            Sil
          </button>
        </div>
      </div>
    </div>
  </div>
)}






 
{/* PNL Update Modal */}
{pnlUpdateModalOpen && selectedPosition && (
  <div 
    className="modal-overlay"
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2000
    }}
    onClick={() => setPnlUpdateModalOpen(false)}
  >
    <div 
      className="pnl-update-modal" 
      onClick={e => e.stopPropagation()}
      style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        width: '100%',
        maxWidth: '400px'
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
        <h3>PNL Güncelle: {selectedPosition.symbol}</h3>
        <button 
          onClick={() => setPnlUpdateModalOpen(false)}
          style={{
            background: 'none',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer'
          }}
        >
          ×
        </button>
      </div>
      
      <div style={{ marginTop: '15px' }}>
        <label>Mevcut PNL: ${selectedPosition.pnl || '0'}</label>
        <input
          type="number"
          step="0.01"
          value={newPnlValue}
          onChange={(e) => setNewPnlValue(e.target.value)}
          placeholder="Yeni PNL değeri"
          style={{
            width: '100%',
            padding: '8px',
            marginTop: '5px',
            border: '1px solid #ddd',
            borderRadius: '4px'
          }}
        />
      </div>

      <div style={{ 
        display: 'flex', 
        gap: '10px', 
        marginTop: '20px',
        justifyContent: 'flex-end' 
      }}>
        <button
          onClick={() => setPnlUpdateModalOpen(false)}
          style={{
            padding: '8px 16px',
            borderRadius: '4px',
            border: '1px solid #ddd',
            backgroundColor: 'white',
            cursor: 'pointer'
          }}
        >
          İptal
        </button>
        <button
          onClick={handlePnlUpdate}
          style={{
            padding: '8px 16px',
            borderRadius: '4px',
            border: 'none',
            backgroundColor: '#4f46e5',
            color: 'white',
            cursor: 'pointer'
          }}
        >
          Güncelle
        </button>
      </div>
    </div>
  </div>
)}




  </div>
</div>
);
};

export default TradingDashboard;